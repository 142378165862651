import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image2'

const StyledBannerWithDescription = styled.section`
  position: relative;

  @media (min-width: 992px) {
    padding-top: 150px;
  }

  @media (max-width: 991px) {
    padding-top: 100px;
  }
`

const BannerWithDescriptionBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;

  @media (min-width: 1441px) {
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    transform: translateZ(0);
  }
`

const BannerWithDescriptionBackgroundImageOverlay = styled.div<{
  color: string
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media (min-width: 992px) {
    opacity: 0.95;

    ${(props) =>
      props.color === 'blue' &&
      css`
        background: rgb(2, 217, 237);
        background: linear-gradient(
          90deg,
          rgba(2, 217, 237, 1) 40%,
          rgba(2, 217, 237, 0.35) 80%
        );
      `};

    ${(props) =>
      props.color === 'purple' &&
      css`
        background: rgb(2, 217, 237);
        background: linear-gradient(
          90deg,
          rgba(51, 42, 124, 1) 40%,
          rgba(51, 42, 124, 0.35) 80%
        );
      `};

    ${(props) =>
      props.color === 'orange' &&
      css`
        background: rgb(2, 217, 237);
        background: linear-gradient(
          90deg,
          rgba(235, 98, 9, 1) 40%,
          rgba(235, 98, 9, 0.35) 80%
        );
      `};
  }

  @media (max-width: 992px) {
    opacity: 0.65;

    ${(props) =>
      props.color === 'blue' &&
      css`
        background: rgba(2, 217, 237, 1);
      `};

    ${(props) =>
      props.color === 'purple' &&
      css`
        background: rgba(51, 42, 124, 1);
      `};

    ${(props) =>
      props.color === 'orange' &&
      css`
        background: rgba(235, 98, 9, 1);
      `};
  }
`

const BannerWithDescriptionBackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;

  @media (max-width: 992px) {
    & img {
      object-position: 70%;
    }
  }
`

const BannerWithDescriptionDescription = styled(ParseContent)<{
  color: string
  view: string
}>`
  @media (max-width: 575px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      font-size: ${(props) => props.theme.font.size.l};
      line-height: ${(props) => props.theme.font.size.l};
    }
  }

  ${(props) =>
    (props.color === 'purple' || props.color === 'orange') &&
    css`
      color: ${props.theme.color.text.light};

      & p,
      & h1,
      & h2,
      & strong {
        color: ${props.theme.color.text.light};
      }
    `};

  @media (max-width: 991px) {
    color: ${(props) => props.theme.color.text.light};
    font-weight: ${(props) => props.theme.font.weight.m};

    & p,
    & h1,
    & h2,
    & strong {
      color: ${(props) => props.theme.color.text.light};
    }
  }

  ${(props) =>
    props.view === 'big' &&
    css`
      padding-bottom: 150px;
    `};

  ${(props) =>
    props.view === 'flex' &&
    css`
      @media (min-width: 992px) {
        min-height: 350px;
      }
    `};
`

interface BannerWithDescriptionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BannerWithDescription
}

const BannerWithDescription: React.FC<BannerWithDescriptionProps> = ({
  fields,
}) => (
  <StyledBannerWithDescription>
    <BannerWithDescriptionBackground>
      <BannerWithDescriptionBackgroundImage
        alt="AddComm"
        image={fields?.backgroundImage || fields?.background_image}
        loading="eager"
      />
      <BannerWithDescriptionBackgroundImageOverlay
        color={fields?.backgroundColor || fields?.background_color || ''}
      />
    </BannerWithDescriptionBackground>
    <div className="container">
      <div className="row">
        <div className="col-md-6 py-lg-5 mb-5">
          <BannerWithDescriptionDescription
            color={fields?.backgroundColor || fields?.background_color || ''}
            content={fields?.description}
            view={fields?.view || 'flex'}
          />
        </div>
      </div>
    </div>
  </StyledBannerWithDescription>
)

export default BannerWithDescription
